
.chat-chat {
	z-index: 100;
	position: absolute;
	top: 0;
	bottom: 0;
	right: var(--menuColumnWidth-n);
	width: var(--menuColumnWidth);
	margin-top: var(--menuBarHeight);
	margin-bottom: var(--menuFooterHeight);
	
	transition: right 0.5s ease;
	
	background-color: var(--color-base);
}

@media (max-width: 575.98px) {
	.chat-chat {
		right: -100%;
		width: 100%;
		transition: none;
	}
}

.chat-chat-active {
	right: 0;
}

.chat-chatFeed-topSpace{
	height: calc(100% - 6.3em);
}

.chat-chatFeed {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin-bottom: 1em;
}

.chat-chatFeed-seeNewButton {
	transition: all 0.5s ease;
	transition-property: bottom, opacity;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	bottom: 0em;
	height: 2em;
	opacity: 0;
}

.chat-chatFeed-seeNewButton-active {
	bottom: 2em;
	opacity: 0.75;
}

.chat-message-body {
	flex: 1 1;
	padding: 0.5em;
}
.chat-chatInput {
	position: absolute;
	bottom: 0;
	height: 2em;
    width: 100%;
}

.chat-chatInputBox {
    height: 100%;
    width: auto;
}

.chat-form {
    display:flex;
    height: 100%;
}

.chat-messages {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 2em);
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
}

.chat-message-header {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--color-base-font-subtle);
	font-size: 0.9em;
}

.chat-sendChatButton {
    width: 20%;
    height: 100%;
    border-radius: 0;
}

.chat-newMessageInput {
    width: 100%;
    height: 100%;
}
