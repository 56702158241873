.howToPlay {
	z-index: 104;
    /* taken from https://www.w3schools.com/howto/howto_css_overlay.asp */
    position: fixed; /* Sit on top of the page content */
    width: 100vw; /* Full width (cover the whole page) */
    height: 100vh; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    padding: 2em;
}

@media (max-width: 575.98px) {
	.howToPlay {
		position:relative;
        background-color: transparent;
        width: 100%;
        height: 100%;
        padding: 0;
        z-index: 0;
	}
}

.howToPlay-card {
	z-index: 105;
    height: 100%;
    width: 100%;
}

.howToPlay-content {
    overflow: scroll;
}

.howToPlay-closeButton {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    height: 2em;
    width: 2em;
    background-color: white;
    border: none;
    border-radius: 50%;
    padding: 0;
	line-height: 1.5em;
	color: white;
    z-index: 1;
}

.howToPlay-closeButton-icon {
    width: 100%;
    height: 100%;
    color: black;
    display: block;
	font-size: 0.6em;
}


.howToPlay-closeButton-icon:hover {
    color:darkgrey;
}

.howToPlay-instruction {
    border: 1px solid darkgrey;
    border-radius: 4px;
    margin-bottom: 1em;
    height: calc(100% - 1em);
}