.appMenu-bar {
	z-index: 103;
	position: absolute;
	top: 0;
	width: 100%;
	height: var(--menuBarHeight);
	background-color: var(--color-highlight);

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.appMenu-left {
	display:flex;
	justify-content: start;
	align-items: center;
}

.appMenu-center {
	display:flex;
	justify-content: center;
	text-align: center;
}

.appMenu-right {
	display:flex;
	justify-content: end;
	align-items: center;
}

.appMenu-footer {
	display: none;
	z-index: 103;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: var(--menuFooterHeight);
	
	background-color: var(--color-highlight);
}

.appMenu-bar-shadow {
	z-index: 101;
	position: absolute;
	left: 0;
	top: var(--menuBarHeight);
	width: 100%;
	height: 5px;
	
	pointer-events: none;
	background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0));
}

.appMenu-menuButton {
	position: absolute;
	left: 0.5em;
}

.appMenu-name {
	max-width: calc(100% - 8em);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: absolute;
	bottom: 0;
	right: 4em;
	
	transition: color 0.1s ease;
}

.appMenu-button span {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	line-height: 1.5em;
	color: white;
	transform: scaleX(-1);
	font-size: 0.6em;
}

.appMenu-button:hover span {
	color: black;
}

.appMenu-button {
	z-index: 105;
	font-size: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1.5em;
	width: 1.5em;
	
	transition: color 0.1s ease;
	border: 1px solid black;
	color: black;
	border-radius: 10%;

	margin-right: 0.5em;
}

@media (max-width: 575.98px) {
	.appMenu-button {
		margin-right: 0;
	}
}

.appMenu-button:hover {
	color: var(--color-button-highlight);
}

.appMenu-menu {
	font-size: 2em;
	z-index: 100;
	position: absolute;
	top:0;
	bottom: 0;
	left: var(--menuColumnWidth-n);
	width: var(--menuColumnWidth);
	margin-top: var(--menuBarHeight);
	
	transition: left 0.5s ease;
	
	background-color: var(--color-base);
}

@media (max-width: 575.98px) {
	.appMenu-menu {
		left: -100%;
		width: 100%;
		transition: none;
	}
}

.appMenu-menu-active {
	left: 0;
}

.appMenu-ad {
	position:absolute;
	bottom: 0;
}

