
.title-title {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	position: absolute;
	cursor: pointer;
}

.title-title:hover {
	text-decoration: none;
}

.title-smallish {
    color: lightblue;
	margin-bottom: 0;
}

.title-epic {
    color: white;
    font-family: monospace;
}

.title-brown{
    color:lightpink;
}