.navWarning {
	z-index: 108;
    position: fixed;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    background: rgba(0,0,0,0.5);
    color: white;
    font-size: 2rem;
    flex-direction: column;
    text-align: center;
}

.navWarning button {
    margin-bottom: 1em;
}

.navWarning button:hover {
    cursor: pointer;
    filter: brightness(150%);
}