.appContent-main {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin-top: var(--menuBarHeight);
	margin-bottom: var(--menuFooterHeight);
	overflow-y: scroll;
}

.appContent-scrollWrap {
	position: relative;
	width: 100%;
	height: 100%;
}

.appContent-header {
	height: 10px;
}

.appContent-footer {
	height: 10em;
}

.appContent-widthHandler {
	max-width: calc(100vh - 6em);
}