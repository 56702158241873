
.timer-container {
	position: absolute;
	top: 4em;
	right: 1em;
	background-color: black;
	background-color: rgba(0,0,0,0.5);
	width: 3em;
	height:3em;
	padding: 0.5em;
	border-radius: 0.5em;
	color: lightblue;
	text-align: center;
}


@keyframes timer-secondSpin {
	0% {transform: rotate(-90deg);}
	25% {transform: rotate(0deg);}
	50% {transform: rotate(90deg);}
	75% {text-replace: rotate(180deg);}
	100% {transform: rotate(270deg);}


}

.timer-clockHand {
	position: absolute;
	height: 0.1em;
	background-color: black;
	left: 50%;
	top:50%;
	width: 1.1em;
	animation: timer-secondSpin 1s infinite linear;
	transform-origin: 0% 50%;
}

.timer-content {
	position: absolute;
	text-align: center;
	left: 50%;
	top:50%;
	transform: translate(-50%,-50%);
}

.timer-warning {
	color: gold;
	font-size: 1.2em;
	font-weight: bold;
}

.timer-danger {
	color: red;
	font-size: 2em;
	font-weight: bolder;
}