.nav {
    display: flex;
    flex-direction: column;
}

.menu-btn {
	padding-left: 1em;
	transition: all 0.2s ease;
    color: #222222;
}

.menu-btn:hover {
	padding-left: 1.5em;
	background-color: var(--color-base-subtle);
    text-decoration: none;
    color: black;
}

a.active {
	padding-left: 1.5em;
	background-color: var(--color-highlight) !important;
    color: black;
    &:hover {
        text-decoration: none;
    }
}